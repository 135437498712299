import {Image} from '@mantine/core';
import {Spotlight, type SpotlightActionData, spotlight} from '@mantine/spotlight';
import {useNavigate} from '@remix-run/react';
import {IconSearch} from '@tabler/icons-react';
import React, {useEffect, useMemo} from 'react';

import {useFetcher, useMatches} from '~/features/serialization';
import type {loader} from '~/routes/_landing._index/route';

function isMatchWithRouteMask<T extends ReturnType<typeof useMatches>[number]>(
  match: T
): match is T & {handle: {routeMask: string}} {
  return (
    typeof match.handle === 'object' &&
    !!match.handle &&
    'routeMask' in match.handle &&
    typeof match.handle?.routeMask === 'string'
  );
}

export interface ZoneFinderProps {
  trigger: (onTrigger: () => void) => React.ReactNode;
}

export function ZoneFinder({trigger}: ZoneFinderProps) {
  const fetcher = useFetcher<typeof loader>();
  const matches = useMatches();
  const navigate = useNavigate();

  const currentRouteMask = useMemo(() => {
    const mask = matches
      .slice(0)
      .reverse()
      .filter(isMatchWithRouteMask)
      .find(m => m.handle?.routeMask?.includes('$zoneSlug'))?.handle?.routeMask;
    return mask || '/$zoneSlug';
  }, [matches]);

  const actions = useMemo(() => {
    if (fetcher.state !== 'idle' || !fetcher.data) {
      return [];
    }

    const actionData: SpotlightActionData[] = fetcher.data.zones.map(zone => ({
      id: zone.name,
      label: zone.name,
      description: zone.description,
      leftSection: <Image src={zone.logo} w={50} fit="contain" />,
      onClick: () => navigate(currentRouteMask.replace('$zoneSlug', zone.slug)),
    }));

    return actionData;
  }, [fetcher, navigate, currentRouteMask]);

  useEffect(() => {
    if (fetcher.state === 'idle' && !fetcher.data) {
      fetcher.load('/?index');
    }
  }, [fetcher]);

  return (
    <>
      {trigger(spotlight.open)}
      <Spotlight
        actions={actions}
        searchProps={{
          leftSection: <IconSearch size="1.125rem" />,
          placeholder: 'Search blockchains',
        }}
        shortcut="mod + k"
        radius={0}
        limit={actions.length}
        disabled={fetcher.state !== 'idle' || !fetcher.data}
        nothingFound="No blockchains found"
        scrollable
        maxHeight={600}
      />
    </>
  );
}
