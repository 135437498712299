import {useOs} from '@mantine/hooks';

interface OsModKeyProps {
  fallback?: string;
}

export function OsModKey({fallback = 'Mod'}: OsModKeyProps) {
  const os = useOs();

  if (os === 'undetermined') {
    return <>{fallback}</>;
  }

  return <>{os === 'macos' ? '⌘' : 'Ctrl'}</>;
}
