import classes from './Footer.module.css';

import { ActionIcon, Box, type BoxProps, Divider, Group, Text } from '@mantine/core';
import { IconBrandDiscord, IconBrandX } from '@tabler/icons-react';
import cx from 'clsx';

interface FooterProps extends BoxProps {}

export function Footer({ className, ...others }: FooterProps) {
  return (
    <Box component="footer" className={cx(classes.root, className)} {...others}>
      <Group gap="xs" wrap="nowrap">
        <ActionIcon
          component="a"
          href="https://x.com/observatoryzone"
          rel="noreferrer"
          target="_blank"
          className={cx(classes.link, classes['link-twitter'])}>

          <IconBrandX />
        </ActionIcon>
        <Divider orientation="vertical" />
        <ActionIcon
          component="a"
          href="https://discord.gg/zawuNJzVu4"
          rel="noreferrer"
          target="_blank"
          className={cx(classes.link, classes['link-discord'])}>

          <IconBrandDiscord />
        </ActionIcon>
        <Divider orientation="vertical" />
        <Text component="a" href="https://rockawayx.com/" target="_blank" rel="noreferrer" c="dimmed" size="xs">
          Created by{' '}
          <strong>
            Rockaway<sup>X</sup> Labs
          </strong>
        </Text>
      </Group>
    </Box>);

}