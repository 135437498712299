import classes from './SidebarLinks.module.css';

import type { BoxProps } from '@mantine/core';
import { Box, Group, Space, Stack, Text } from '@mantine/core';
import {
  IconAbacus,
  IconAffiliate,
  IconDashboard,
  IconMap,
  IconMap2,
  IconMoneybag,
  IconNetwork,
  IconServer,
  IconTimeline } from
'@tabler/icons-react';
import cx from 'clsx';

import { LinkBuilder } from '@rockawayxlabs/observatory-utils';

import { NavLink } from '~/features/links';

interface SidebarLinksProps extends BoxProps {
  zoneSlug: string;
  onCollapsedChange: (value: boolean) => void;
}

export function SidebarLinks({ zoneSlug, onCollapsedChange, ...others }: SidebarLinksProps) {
  const linkBuilder = LinkBuilder.zone(zoneSlug);

  const links = [
  {
    to: linkBuilder.dashboard().toString(),
    icon: <IconDashboard size="1rem" />,
    label: 'Dashboard',
    end: true
  },
  {
    to: linkBuilder.newsfeed().toString(),
    icon: <IconTimeline size="1rem" />,
    label: 'Newsfeed'
  },
  ...(zoneSlug === 'dydx' ?
  [
  {
    to: linkBuilder.mev().blocks().toString(),
    icon: <IconMoneybag size="1rem" />,
    label: 'MEV'
  }] :

  []),
  {
    to: linkBuilder.governance().toString(),
    // TODO: please change this
    icon: <IconAbacus size="1rem" />,
    label: 'Governance'
  },
  {
    to: linkBuilder.validators().toString(),
    icon: <IconMap2 size="1rem" />,
    label: 'Validators'
  },
  {
    to: linkBuilder.countries().toString(),
    icon: <IconMap size="1rem" />,
    label: 'Countries'
  },
  {
    to: linkBuilder.isps().toString(),
    icon: <IconNetwork size="1rem" />,
    label: 'Internet Service Providers'
  },
  {
    to: linkBuilder.asns().toString(),
    icon: <IconAffiliate size="1rem" />,
    label: 'Autonomous Systems'
  }];


  return (
    <Box component="nav" {...others}>
      <Stack gap="xs">
        {links.map((link) =>
        <NavLink
          key={link.to}
          to={link.to}
          className={({ isActive }) => cx(classes.link, { [classes.linkInactive ?? '']: !isActive })}
          end={link.end || false}
          onClick={() => onCollapsedChange(true)}>

            <Group gap="xs">
              {link.icon}
              <Text size="sm" fw={600}>
                {link.label}
              </Text>
            </Group>
          </NavLink>
        )}
        <Space h="xl" />
        <NavLink
          to="nodes"
          className={({ isActive }) => cx(classes.link, { [classes.linkInactive ?? '']: !isActive })}
          onClick={() => onCollapsedChange(true)}>

          <Group gap="xs">
            <IconServer size="1rem" />
            <Text size="sm">Raw Nodes</Text>
          </Group>
        </NavLink>
      </Stack>
    </Box>);

}