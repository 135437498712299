import classes from './route.module.css';

import { Group } from '@mantine/core';
import type { LoaderFunctionArgs } from '@remix-run/node';
import { Outlet } from '@remix-run/react';
import invariant from 'tiny-invariant';

import { AppError } from '~/components/AppErrorBoundary';
import { SensorIcon } from '~/components/SensorIcon';
import { prisma } from '~/db.server';
import { Link } from '~/features/links';
import { getZoneWithLatestSyncAndScore } from '~/features/observatory-model.server';
import { json, useLoaderData } from '~/features/serialization';
import { URLParams } from '~/utils/URLParams';
import { assertFinishedSync, assertWithResponse } from '~/utils/assert';
import { createRouteHandleWithBreadcrumb } from '~/utils/breadcrumbs';
import { sensorZoneSlugs } from '~/utils/zone.server';

import { Header } from './components/Header';
import { Sidebar } from './components/Sidebar';

export async function loader({ params, request }: LoaderFunctionArgs) {
  const { zoneSlug } = params;
  invariant(zoneSlug, 'zoneSlug param not found');

  const urlParams = new URLParams(request.url);
  const syncDate = urlParams.getSyncDate();

  const zone = await getZoneWithLatestSyncAndScore(prisma, { syncDate, zoneSlug });
  assertWithResponse(zone, AppError.BLOCKCHAIN_NOT_FOUND, { status: 404 });
  assertWithResponse(zone.enabled, AppError.BLOCKCHAIN_NOT_READY, { status: 403 });
  assertFinishedSync(zone.latestSync);

  return json({ zone: { ...zone, sensorEnabled: sensorZoneSlugs.has(zone.slug) } });
}

export const handle = {
  routeMask: '/$zoneSlug',
  ...createRouteHandleWithBreadcrumb<typeof loader>(
    (route) =>
    route.data &&
    <Group key={route.pathname}>
          <Link to={route.pathname}>{route.data.zone.name}</Link>
          <SensorIcon sensorEnabled={route.data.zone.sensorEnabled} />
        </Group>

  )
};

export default function ZonePageLayout() {
  const { zone } = useLoaderData<typeof loader>();

  return (
    <div className={classes.root}>
      <Header className={classes.header} />
      <Sidebar zone={zone} className={classes.sidebar} />
      <main className={classes.main}>
        <Outlet />
      </main>
    </div>);

}

export { ErrorBoundary } from '~/components/AppErrorBoundary';