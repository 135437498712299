import classes from './index.module.css';

import type { BoxProps } from '@mantine/core';
import { Box, Button, Center, Divider, Group, Image, Paper, Text, Title, UnstyledButton, rem } from '@mantine/core';
import { IconChevronDown, IconChevronUp, IconSwitchHorizontal } from '@tabler/icons-react';
import cx from 'clsx';
import { useState } from 'react';

import { ClientOnly } from '~/components/ClientOnly';
import { OsModKey } from '~/components/OsModKey';
import { ScoreRing } from '~/components/ScoreRing';
import { SyncTextBadge } from '~/components/SyncBadge';
import { ZoneFinder } from '~/components/ZoneFinder';
import type { ZoneWithLatestSyncAndScore } from '~/features/observatory-model.server';

import { Footer } from './Footer';
import { SidebarLinks } from './SidebarLinks';

interface SidebarProps extends BoxProps {
  zone: ZoneWithLatestSyncAndScore;
}

export function Sidebar({ zone, ...others }: SidebarProps) {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <Box {...others}>
      <Paper withBorder shadow="sm">
        <div className={classes.header}>
          <Center className={classes['header-logo']}>
            <Image src={zone.logo} w="100%" fit="contain" />
          </Center>
          <Divider orientation="vertical" />
          <div className={classes['header-text']}>
            <Title order={1} size="h2" className={classes.title}>
              {zone.name}
            </Title>
            <Text tt="uppercase" size="sm" fw={500} lts={2} lineClamp={1} className={classes.description}>
              {zone.description}
            </Text>
          </div>
        </div>
        <div className={classes.menu} data-collapsed={collapsed}>
          {zone.score &&
          <>
              <Divider />
              <Box p="lg">
                <ScoreRing size={200} thickness={12} score={zone.score.health} labelProps={{ size: rem(60) }} />
                <SyncTextBadge ta="center" size="sm" syncedAt={zone.latestSync?.updatedAt} />
              </Box>
            </>}

          <Divider />
          <SidebarLinks p="lg" zoneSlug={zone.slug} onCollapsedChange={setCollapsed} />
          <Divider />
          <Box px="lg" py="md" className={classes['switch-zone']}>
            <ZoneFinder
              trigger={(openSpotlight) =>
              <UnstyledButton
                className={cx(classes['switch-zone-btn'], classes['link-inactive'])}
                onClick={() => openSpotlight()}>

                  <Group justify="space-between" wrap="nowrap">
                    <Group gap="xs">
                      <IconSwitchHorizontal size="1rem" />
                      <Text size="xs">Switch Blockchain</Text>
                    </Group>
                    <ClientOnly>
                      {() =>
                    <Text size="xs">
                          <OsModKey /> + K
                        </Text>}

                    </ClientOnly>
                  </Group>
                </UnstyledButton>} />


          </Box>
        </div>
        <div className={classes['menu-toggle']}>
          <Divider />
          <Button
            leftSection={collapsed ? <IconChevronDown size="1rem" /> : <IconChevronUp size="1rem" />}
            variant="subtle"
            fullWidth
            onClick={() => setCollapsed((s) => !s)}>

            Toggle menu
          </Button>
        </div>
      </Paper>
      <Footer className={classes.footer} data-collapsed={collapsed} />
    </Box>);

}