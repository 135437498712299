import classes from './index.module.css';

import { Group, Paper, type PaperProps } from '@mantine/core';
import cx from 'clsx';

import { ColorSchemeSwitch } from '~/components/ColorSchemeSwitch';
import { Logo } from '~/components/Logo';
import { Link } from '~/features/links';

import { Breadcrumbs } from './Breadcrumbs';

interface HeaderProps extends PaperProps {}

export function Header({ className, ...others }: HeaderProps) {
  return (
    <Paper component="header" withBorder shadow="sm" className={cx(classes.root, className)} {...others}>
      <Group gap="xl" px="md" className={classes['full-height']} wrap="nowrap">
        <Link to="/" className={classes['home-link']}>
          <Logo className={classes.logo} />
        </Link>

        <Breadcrumbs visibleFrom="sm" />

        <ColorSchemeSwitch ml="auto" />
      </Group>
    </Paper>);

}