import classes from './Breadcrumbs.module.css';

import { Group, type GroupProps, Text } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import cx from 'clsx';
import { Fragment, useMemo } from 'react';

import { useMatches } from '~/features/serialization';
import { extractBreadcrumbsFromRouteMatches } from '~/utils/breadcrumbs';

export function Breadcrumbs({ className, ...others }: GroupProps) {
  const matches = useMatches();
  const breadcrumbs = useMemo(() => extractBreadcrumbsFromRouteMatches(matches), [matches]);

  return (
    <Group gap="xl" className={cx(classes.root, className)} {...others}>
      {breadcrumbs.map(({ id, breadcrumb }) =>
      <Fragment key={id}>
          <IconChevronRight className={classes.separator} />
          <Text fw={500} component="div" className={classes.breadcrumb}>
            {breadcrumb}
          </Text>
        </Fragment>
      )}
    </Group>);

}